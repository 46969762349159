import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDIuHzArqjMW2T9qM7LdqCW3-u0rH21GlY",
    authDomain: "xoros-mou.firebaseapp.com",
    databaseURL: "https://xoros-mou.firebaseio.com",
    projectId: "xoros-mou",
    storageBucket: "xoros-mou.appspot.com",
    messagingSenderId: "494011327845",
    appId: "1:494011327845:web:78cb43bd3fd1eaffcfad8c",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export default firebase;
