import React, { useState } from "react";
import { Layout } from "../components/common";
//import { MetaData } from '../components/common/meta'
//import ContentContext from '../components/common/ContentContext';
import { firestore } from "../components/Firebase/firebase";

const ContactPage = () => {
    const [tel, setTel] = useState(``);
    const [mes, setMes] = useState(``);
    const [show, setShow] = useState(false);

    const changeTel = (e) => {
        setTel(e.target.value);
    };

    const changeMes = (e) => {
        setMes(e.target.value);
    };

    const sendData = async (e) => {
        e.preventDefault();
        const data = { caseOpen: true, mes: mes, tel: tel };
        await firestore.collection(`call-back-messages`).add(data);
        setTel(``);
        setMes(``);
        setShow(true);
        setTimeout(() => setShow(false), 3000);
    };

    return (
        <>
            {show && (
                <div className="message">
                    <p>
                        Ευχαριστούμε για το μύνημά σας, θα επικοινωνήσουμε μαζί
                        σας το συντομότερο δυνατό!
                    </p>
                </div>
            )}
            <Layout>
                <div className="container">
                    <article className="content">
                        <h1 className="content-title">Επικοινωνία</h1>
                        <section className="content-body load-external-scripts">
                            <h3 className="t-center">
                                Tηλεφωνικό κέντρο 215 0000279
                            </h3>
                            <h3 className="t-center">
                                <span
                                    className="email"
                                    data-user="ofni"
                                    data-website="rg.uomsorox"
                                ></span>
                            </h3>
                            <hr />
                            <div className="two-thirds-2-1">
                                <div>
                                    <h3>
                                        'Ωρες λειτουργίας τηλεφωνικού κέντρου
                                    </h3>
                                    <p>
                                        Δευτέρα - Παρασκευή 9.00 - 19.00
                                        <br />
                                        Σάββατο 9.00 - 17.00
                                        <br />
                                        Κυριακή κλειστά{" "}
                                    </p>
                                    <h4>Διεύθυνση γραφείων</h4>
                                    <p>
                                        Εθνικής Αντιστάσεως 53, 15772 Ιλίσια,
                                        1ος όροφος
                                    </p>
                                    <div className="contact-box">
                                        <p className="t-middle">
                                            Αφήστε μας το τηλέφωνό σας και θα
                                            σας καλέσουμε καλέσουμε εμείς το
                                            συντομότερο δυνατό!
                                        </p>
                                        <form
                                            classNameName="contact"
                                            onSubmit={sendData}
                                        >
                                            <label htmlFor="tel">
                                                Τηλέφωνο επικοινωνίας:
                                            </label>
                                            <input
                                                type="text"
                                                id="tel"
                                                name="tel"
                                                onChange={changeTel}
                                                value={tel}
                                            />
                                            <label htmlFor="mes">
                                                Μήνυμα{" "}
                                                <span className="t-small">
                                                    (προαιρετικά)
                                                </span>
                                                :
                                            </label>
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                type="text"
                                                id="mes"
                                                name="mes"
                                                onChange={changeMes}
                                                value={mes}
                                            ></textarea>
                                            <br />
                                            <input
                                                type="submit"
                                                value="Aποστολή"
                                                id="sent-btn"
                                            />
                                        </form>
                                        <p className="t-small v-space">
                                            Με την καταχώρηση και αποστολή των
                                            στοιχείων επικοινωνίας σας,
                                            αποδέχετεστε την{" "}
                                            <a href="https://xorosmou.gr/aporito-cookie-policy/">
                                                πολιτική απορρήτου.
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <img src="https://cms.xorosmou.online/content/images/2020/05/marketing-office-working-business-33999.jpg" />
                            </div>

                            <p className="t-small v-space">
                                Το μεγαλύτερο μέρους του χρόνου μας όλη μας η
                                ομάδα θέλουμε να είναι κοντά στον πελάτη. Γι
                                αυτό και απο το ξεκίνημά μας και λόγω του τύπου
                                της δουλειάς μας έχουμε οργανωθεί, ώστε να
                                δουλεύουμε απομεμακρυσμένα με τηλεεργασία, αλλά
                                ταυτόχρονα να επικοινωνούμε και σαν ομάδα που
                                είναι κοντά. Ένας παραπάνω λόγος σήμερα που λόγω
                                των συμβάντων της πανδημίας με τον κορονοιό
                                COVID - 19, το γραφείο μας θα παραμείνει κλειστό
                                για το κοινό, μέχρι νεωτέρας. Για οτιδήποτε
                                χρειαστείτε αυτή την περίοδο μπορείτε να μας
                                καλέσετε στο τηλεφωνικό μας κέντρο, να
                                επικοινωνήσετε μαζί μας μέσω email, είτε να
                                κλείσουμε ένα ραντεβού στον χώρο σας.
                            </p>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

export default ContactPage;
